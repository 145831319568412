<template>
  <div>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>
    <v-card outlined>
      <v-card-text class="px-sm-16 py-8">
        <v-card-title class="text-Heading6 OnSurface050">商戶資訊</v-card-title>
        <v-card-text>
          <v-row class="my-1">
            <v-col
              cols="12"
              sm="2"
              class="text-Body OnSurface500--text d-flex align-center"
            >
              商戶 ID
            </v-col>
            <v-col cols="12" sm="10" class="text-Body OnSurface900--text">
              {{ merchantSerial }}
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="my-1">
            <v-col
              cols="12"
              sm="2"
              class="text-Body OnSurface500--text d-flex align-center"
            >
              商戶名稱
            </v-col>
            <v-col cols="12" sm="10" class="text-Body OnSurface900--text">
              {{ merchantName }}
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-title class="text-Heading6 OnSurface050">
          代收代付 API
        </v-card-title>
        <v-card-text>
          <v-row class="my-1">
            <v-col
              cols="12"
              sm="2"
              class="text-Body OnSurface500--text flex-center text-no-wrap"
            >
              建立
              <span class="primary--text Extrabold mx-1">代收</span>
              訂單
            </v-col>
            <v-col cols="12" sm="10" class="text-Body OnSurface900--text">
              <v-text-field
                rounded
                dense
                hide-details
                readonly
                :value="`${apiBaseUrl}/v1/collectApi/place`"
                @focus="copy(`/v1/collectApi/place`)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="my-1">
            <v-col
              cols="12"
              sm="2"
              class="text-Body OnSurface500--text flex-center text-no-wrap"
            >
              建立
              <span class="alert--text Extrabold mx-1">代付</span>
              訂單
            </v-col>
            <v-col cols="12" sm="10" class="text-Body OnSurface900--text">
              <v-text-field
                rounded
                dense
                hide-details
                readonly
                :value="`${apiBaseUrl}/v1/payApi/place`"
                @focus="copy(`/v1/payApi/place`)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-title class="text-Heading6 OnSurface050">查詢 API</v-card-title>
        <v-card-text>
          <v-row class="my-1">
            <v-col
              cols="12"
              sm="2"
              class="text-Body OnSurface500--text flex-center text-no-wrap"
            >
              檢查
              <span class="primary--text Extrabold mx-1">代收</span>
              訂單狀態
            </v-col>
            <v-col cols="12" sm="10" class="text-Body OnSurface900--text">
              <v-text-field
                rounded
                dense
                hide-details
                readonly
                :value="`${apiBaseUrl}/v1/collectApi/check`"
                @focus="copy(`/v1/collectApi/check`)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="my-1">
            <v-col
              cols="12"
              sm="2"
              class="text-Body OnSurface500--text flex-center text-no-wrap"
            >
              檢查
              <span class="alert--text Extrabold mx-1">代付</span>
              訂單狀態
            </v-col>
            <v-col cols="12" sm="10" class="text-Body OnSurface900--text">
              <v-text-field
                rounded
                dense
                hide-details
                readonly
                :value="`${apiBaseUrl}/v1/payApi/check`"
                @focus="copy(`/v1/payApi/check`)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="my-1">
            <v-col
              cols="12"
              sm="2"
              class="text-Body OnSurface500--text flex-center text-no-wrap"
            >
              檢查
              <span class="alert--text Extrabold mx-1">代付</span>
              訂單狀態(多筆)
            </v-col>
            <v-col cols="12" sm="10" class="text-Body OnSurface900--text">
              <v-text-field
                rounded
                dense
                hide-details
                readonly
                :value="`${apiBaseUrl}/v1/payApi/checks`"
                @focus="copy(`/v1/payApi/checks`)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="my-1">
            <v-col
              cols="12"
              sm="2"
              class="text-Body OnSurface500--text flex-center text-no-wrap"
            >
              查詢
              <span class="merchant--text Extrabold mx-1">商戶</span>
              餘額總覽
            </v-col>
            <v-col cols="12" sm="10" class="text-Body OnSurface900--text">
              <v-text-field
                rounded
                dense
                hide-details
                readonly
                :value="`${apiBaseUrl}/v1/payApi/query`"
                @focus="copy(`/v1/payApi/query`)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-title class="text-Heading6 OnSurface050">其他資訊</v-card-title>
        <v-card-text>
          <v-row class="my-1">
            <v-col
              cols="12"
              sm="2"
              class="text-Body OnSurface500--text flex-center text-no-wrap"
            >
              商戶金鑰
            </v-col>
            <v-col cols="12" sm="10" class="text-Body OnSurface900--text">
              <v-btn
                rounded
                depressed
                color="primary"
                @click="showDialog = true"
              >
                點擊查看
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="my-1">
            <v-col
              cols="12"
              sm="2"
              class="text-Body OnSurface500--text flex-center text-no-wrap"
            >
              參考文件
            </v-col>
            <v-col cols="12" sm="10" class="text-Body OnSurface900--text">
              <a
                href="/CMS_Open_API_Documentation.pdf"
                download="Happy_Night_Open_API_Documentation.pdf"
              >
                下載參考文件
              </a>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card-text>
      </v-card-text>
    </v-card>

    <ViewKeyForm :value="showDialog" @close="showDialog = false" />
  </div>
</template>

<script>
import ViewKeyForm from '@/components/MerchantPages/channel/ViewKeyForm.vue';
import { getMerchant } from '@/api/merchantCenter/merchants';

export default {
  components: {
    ViewKeyForm
  },

  data() {
    return {
      showDialog: false,
      merchantSerial: null,
      merchantName: null,
      apiBaseUrl: this.$apiURL
    };
  },

  async created() {
    await this.init();
  },

  methods: {
    async init() {
      const { serial, name } = await getMerchant();
      this.merchantSerial = serial;
      this.merchantName = name;
    },

    copy(v) {
      navigator.clipboard.writeText(`${this.apiBaseUrl}${v}`);
      this.$toast('已複製路徑');
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
